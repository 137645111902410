.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
}

.loader-img {
  position: relative;
  width: 100%;
  text-align: center;
}

.loader-img .back-img {
  width: 400px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.loader-img .main-img {
  width: 291px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.loader-img .logo-img {
  position: relative;
  width: 78px;
  margin-top: 26px;
}

.loader .loader-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader .loader-area .css-1mxr1bq,
.loader .loader-area .css-8vx899,
.loader .loader-area .css-anak8p {
  width: 80px;
  height: 80px;
}

.loader .loader-area .loading-text {
  color: #888;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
}

.loader .loader-area .css-1i5m00m .css-m5syh7,
.loader .loader-area .css-1i5m00m .css-8q43r4 {
  background-color: #6B1A10;
  height: 70px;
  width: 70px;
}